import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import classes from './Agreement.module.scss';
import cx from 'classnames';
import { AlertPopupHandler } from 'components/AlertPopup';
import { updateContractStatus } from 'store/actions/contract';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { get } from 'lodash';

import PartnerManager from '../PartnerManager';
import useBreakpoint from 'helpers/useBreakPoint';
import { doLogout } from 'store/actions/authActions';
import history from 'helpers/history';

const Agreement = ({
  handleDeclineAgreement,
  isBackDropRemoved,
  signModalHeight,
}) => {
  const dispatch = useDispatch();
  const isMobile = useBreakpoint('xs', 'down');
  const [loading, setLoading] = useState(false);

  const contract = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.contract', {})
  );
  const studyType = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.contract.type', '')
  );

  const onboard = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.onboard', false)
  );
  const email = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.contract.email', null)
  );
  const token = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.token', null)
  );
  const companyId = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.user.company.id', null)
  );
  const initiativeId = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.initiative.id', null)
  );
  const partnerManager = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.partner_manager', null)
  );

  const type = useSelector(({ auth }) =>
    get(auth, 'clientContract.type', null)
  );

  const [showAgreement, setShowAgreement] = useState(false);

  const mobileStyle =
    isMobile && !isBackDropRemoved ? { height: `${signModalHeight}px` } : {};

  const handleShowAgreement = () => {
    if (!showAgreement) {
      dispatch(updateContractStatus(initiativeId, { status: 'expanded' }));
    }
    setShowAgreement(!showAgreement);
  };

  const alertContent = (
    <div className={classes.successAlertContent}>
      <p className={cx(classes.description, 'mb-4')}>
        You've successfully executed your{' '}
        <span className="text-uppercase">{contract?.type}</span> Service Order
        Agreement. A copy of this agreement has been sent to your email address.
        A Strike team member will be in touch with you shortly.
      </p>
      {partnerManager && (
        <div>
          <PartnerManager />
        </div>
      )}
    </div>
  );

  return (
    <Card
      style={mobileStyle}
      className={cx(classes.card, {
        [classes.showAgreement]: isBackDropRemoved,
      })}
    >
      <CardBody className={classes.cardBody}>
        <div className={classes.content}>{parse(contract?.summary || '')}</div>
        <div
          className={cx(classes.agreement, {
            [classes.show]: showAgreement,
          })}
        >
          <div className={classes.mainAgreement}>
            {parse(contract?.content || '')}
          </div>
          <div
            className={cx(classes.action, {
              [classes.blur]: !showAgreement,
            })}
          >
            <Button
              onClick={handleShowAgreement}
              rightIcon={
                showAgreement ? (
                  <i className="fas fa-solid fa-angle-up"></i>
                ) : (
                  <i className="fas fa-solid fa-angle-down"></i>
                )
              }
              color="secondary"
            >
              {showAgreement ? 'Hide' : 'Show'} full agreement
            </Button>
          </div>
        </div>
        <div className={classes.stickyWrapper}>
          {type !== 'referrals' && (
            <div className={classes.checkbox}>
              <p className={classes.text}>
                I have read and understand this Engagement and the{' '}
                <a
                  href={
                    studyType === 'ERTC'
                      ? 'https://www.striketax.com/ercterms'
                      : ([3476, 3463].includes(contract?.parent?.id) ? 'https://www.striketax.com/terms' : 'https://www.striketax.com/rdterms')
                  }
                  target="blank"
                  onClick={() =>
                    dispatch(
                      updateContractStatus(initiativeId, {
                        status: 'tc_viewed',
                      })
                    )
                  }
                >
                  Standard Terms & Conditions
                </a>{' '}
                and agree to be bound by them. If I am not the taxpayer
                identified as the Client, I represent and warrant that I have
                authority to bind the Client to this Agreement and hereby do so.
              </p>
            </div>
          )}
          <div className={classes.buttons}>
            <Button
              className={classes.decline}
              onClick={handleDeclineAgreement}
              color="secondary"
            >
              Decline
            </Button>
            <Button
              loading={loading}
              className={classes.agree}
              onClick={async () => {
                setLoading(true);
                let popupProps = {
                  type: 'success',
                  showCancel: false,
                  title: 'Success!',
                  confirmBtnText: onboard ? 'Continue to My Portal' : 'Close',
                  cancelBtnBsStyle: 'outline-default',
                  confirmBtnBsStyle: 'success',
                };

                const { status } = await dispatch(
                  updateContractStatus(
                    initiativeId,
                    { status: type === 'deals' ? 'signed' : 'ra_signed' },
                    type === 'deals' ? 'contract' : 'partner-contract'
                  )
                );
                if (status) {
                  AlertPopupHandler.openCustom({
                    ...popupProps,
                    text: alertContent,
                    customClass: classes.referralAlert,
                    closeOnClickOutside: false,
                    onConfirm: async () => {
                      await dispatch(doLogout(true, true, true));
                      if (onboard) {
                        history.push(
                          `/auth/invitations/accept-and-setup?token=${token}&companyId=${companyId}&email=${encodeURIComponent(
                            email
                          )}`
                        );
                      } else {
                        window.location.href = 'https://www.striketax.com/';
                      }
                    },
                  });
                }
                setLoading(false);
              }}
              color="secondary"
            >
              I agree to these terms
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Agreement;
